<template lang="">
  <div>
    <v-container>
      <v-card :elevation="1" class="v-sheet theme--light br-0">
        <div class="row mt-0 mb-4">
          <div
            class="col-12 col-md-12 m-0 p-0"
            style="margin-top: -5px !important;"
          >
            <DivisorColor :ptl="`3px`" :ptr="`3px`" :pbl="`3px`" :pbr="`3px`" />
          </div>
        </div>
        <v-card-title>
          <v-row class="col-md-12">
            <v-col cols="12" sm="12" md="12">
              <p class="text-h5 font-weight-black mb-0">
                Reportes por temática
              </p>
              <!--<v-subheader class="pl-0 font-weight-black text-subtitle-1 text--blue-grey text--lighten-4">
                                
                            </v-subheader>-->
            </v-col>
          </v-row>

          <v-row class="col-md-12 m-0 p-0">
            <v-divider></v-divider>
          </v-row>
        </v-card-title>
        <v-card-text class="pr-0 pl-0">
          <v-container class="p-8">
            <v-form
              ref="formDatosFinancieros"
              v-on:submit.prevent="buscarProyectos"
              v-model="validFormBusqueda"
            >
              <v-row>
                <v-col cols="12" md="4" sm="6" xs="12" class="pt-0 pb-0">
                  <v-select
                    :clearable="false"
                    v-model="datosBusqueda.tipoConsulta"
                    :items="tiposConsulta"
                    :loading="false"
                    :disabled="btnBusquedaLoading"
                    dense
                    class="required"
                    filled
                    label="Tipo de consulta"
                    :no-data-text="'No se encontraron tipos de consulta'"
                    menu-props="auto"
                    item-text="tipo"
                    item-value="id"
                    :rules="[selectRequired('tipo de consulta')]"
                    @changed="prepararParametros()"
                  ></v-select>
                </v-col>

                <!--inicio sector-->
                <v-col
                  cols="12"
                  md="8"
                  sm="12"
                  xs="12"
                  class="pt-0 pb-0"
                  v-if="datosBusqueda.tipoConsulta == 1"
                >
                  <v-select
                    v-model="datosBusqueda.sectoresId"
                    :items="sectoresBeneficiados"
                    :class="datosBusqueda.tipoConsulta == 1 ? `required` : ``"
                    :clearable="true"
                    dense
                    filled
                    :loading="ddSectoresLoading"
                    :disabled="btnBusquedaLoading"
                    label="Sector Beneficiado"
                    item-text="sector"
                    item-value="id"
                  ></v-select>
                </v-col>
                <!--fin:: sector-->

                <!--inicio territorio-->
                <v-col
                  cols="12"
                  md="8"
                  sm="12"
                  xs="12"
                  class="pt-0 pb-0"
                  v-if="datosBusqueda.tipoConsulta == 2"
                >
                  <v-select
                    v-model="datosBusqueda.departamentosId"
                    :items="departamentos"
                    :loading="departamentosLoading"
                    dense
                    class=""
                    filled
                    label="Departamento"
                    item-text="departamento"
                    item-value="id"
                    :no-data-text="
                      departamentos != null
                        ? 'Seleccione un departamento'
                        : 'No se han encontrado departamentos'
                    "
                  ></v-select>
                </v-col>
                <!--fin:: territorio-->

                <!--inicio institucion-->
                <v-col
                  cols="12"
                  md="8"
                  sm="12"
                  xs="12"
                  class="pt-0 pb-0"
                  v-if="datosBusqueda.tipoConsulta == 3"
                >
                  <v-autocomplete
                    v-model="datosBusqueda.entidadId"
                    :items="instituciones"
                    class=""
                    :clearable="true"
                    dense
                    filled
                    :loading="ddInstitucionesLoading"
                    :disabled="btnBusquedaLoading"
                    label="Institución"
                    :item-text="
                      item =>
                        item.sigla
                          ? `${item.nombreInstitucion} (${item.sigla})`
                          : item.nombreInstitucion
                    "
                    item-value="id"
                  ></v-autocomplete>
                </v-col>
                <!--fin:: institucion-->

                <!--inicio:: fuente cooperante -->

                <v-col
                  cols="12"
                  md="4"
                  sm="6"
                  xs="12"
                  class="pt-0 pb-0"
                  v-if="datosBusqueda.tipoConsulta == 4"
                >
                  <v-select
                    v-model="datosBusqueda.tiposFuenteCooperanteId"
                    :items="tiposFuenteCooperante"
                    :loading="false"
                    dense
                    filled
                    class=""
                    label="Tipo"
                    item-text="nombreTipoFuente"
                    item-value="id"
                    :no-data-text="
                      tiposFuenteCooperante != null
                        ? 'Selecciona un tipo de fuente cooperante'
                        : 'No se han encontrado tipos de fuente cooperante'
                    "
                    menu-props="auto"
                  ></v-select>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                  sm="6"
                  xs="12"
                  class="pt-0 pb-0"
                  v-if="
                    datosBusqueda.tipoConsulta == 4 &&
                      datosBusqueda.tiposFuenteCooperanteId == 1
                  "
                >
                  <v-select
                    v-model="datosBusqueda.fuentesBilateralesId"
                    :items="fuentesBilaterales"
                    :loading="false"
                    :required="datosBusqueda.tiposFuenteCooperanteId == 1"
                    dense
                    class=""
                    filled
                    label="País Fuente Bilateral"
                    item-text="nombre"
                    item-value="id"
                    :no-data-text="
                      fuentesBilaterales != null
                        ? 'Selecciona una institución ejecutora'
                        : 'No se han encontrado unidades ejecutoras'
                    "
                    menu-props="auto"
                  >
                  </v-select>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                  sm="6"
                  xs="12"
                  class="pt-0 pb-0"
                  v-if="
                    datosBusqueda.tipoConsulta == 4 &&
                      datosBusqueda.tiposFuenteCooperanteId == 2
                  "
                >
                  <v-select
                    v-model="datosBusqueda.fuentesMultilateralesId"
                    :items="fuentesMultilateral"
                    :loading="false"
                    dense
                    filled
                    label="Seleccione fuente multilateral"
                    item-text="nombreFuenteMultilateral"
                    item-value="id"
                    :no-data-text="
                      fuentesMultilateral != null
                        ? 'Seleccione una fuente multilateral'
                        : 'No se han encontrado fuentes multilaterales'
                    "
                    menu-props="auto"
                  ></v-select>
                </v-col>

                <!--fin:: fuente cooperante-->

                <!--inicio estado -->
                <v-col
                  cols="12"
                  md="8"
                  sm="12"
                  xs="12"
                  class="pt-0 pb-0"
                  v-if="datosBusqueda.tipoConsulta == 5"
                >
                  <v-select
                    v-model="datosBusqueda.estadoProyectoId"
                    :items="estadosProyecto"
                    :loading="ddEstadosLoading"
                    dense
                    class=""
                    filled
                    label="Estado"
                    item-text="estado"
                    item-value="id"
                    :no-data-text="
                      estadosProyecto != null
                        ? 'Seleccione un estado'
                        : 'No se han encontrado estados'
                    "
                  ></v-select>
                </v-col>
                <!--fin:: estado -->
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                  <v-btn
                    class="white--text float-right  mr-1 mb-2"
                    x-large
                    color="teal lighten-1"
                    :loading="btnBusquedaLoading"
                    :disabled="btnBusquedaLoading || datosBusqueda.tipoConsulta == 0"
                    @click="generarArchivo(datosBusqueda.tipoConsulta, 'xls')"
                    type="submit"
                  >
                    <v-icon left>mdi-file-excel-box</v-icon> Descargar xls
                  </v-btn>

                  <v-btn
                    class="white--text float-right  mr-1 mb-2"
                    x-large
                    color="red lighten-1"
                    :loading="btnBusquedaLoading"
                    :disabled="btnBusquedaLoading || datosBusqueda.tipoConsulta == 0"
                    @click="generarArchivo(datosBusqueda.tipoConsulta, 'pdf')"
                    type="submit"
                  >
                    <v-icon left>mdi-file-pdf-box</v-icon> Descargar pdf
                  </v-btn>

                  <v-btn
                    class="white--text float-right  mr-1 mb-2"
                    x-large
                    color="light-blue-502"
                    :loading="btnBusquedaLoading"
                    :disabled="btnBusquedaLoading || datosBusqueda.tipoConsulta == 0"
                    @click="generarArchivo(datosBusqueda.tipoConsulta, 'csv')"
                    type="submit"
                  >
                    <v-icon left>mdi-file-delimited</v-icon> Descargar csv
                  </v-btn>

                  <!-- <v-btn
                    color="grey lighten-5"
                    elevation="0"
                    class="mb-2 float-right grey lighten-4 mr-2"
                    :loading="false"
                    x-large
                    :disabled="btnBusquedaLoading"
                    @click="limpiarFiltros()"
                  >
                    <v-icon>mdi-trash</v-icon>
                    Limpiar
                  </v-btn> -->
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <v-container>
            <v-row>
              <v-col cols="12"> </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-container>

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
    ></DialogLoader>
    <!---->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->
  </div>
</template>
<script>
import DivisorColor from "@/view/content/biblioteca-componentes/DivisorColor.vue";
import DialogLoader from "@/view/content/DialogLoader";
import { OBTENER_DEPARTAMENTOS_PAIS } from "@/core/services/store/catalogos/departamentos/departamento.module";
import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";
//import { REPORTE_DOCUEMNTOS_CNS } from "@/core/services/store/proyectoscns/proyectocns.module";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";
export default {
  name: "ReporteGeneralCns",
  components: {
    DivisorColor,
    SnackAlert,
    DialogLoader
  },
  data() {
    return {
      validFormBusqueda: false,
      dialogLoaderVisible: false,
      dialogLoaderText: "",
      departamentosLoading: false,
      departamentos: [],
      ddEstadosLoading: false,
      btnBusquedaLoading: false,
      ddSectoresLoading: false,
      ddInstitucionesLoading: false,
      tiposFuenteCooperante: [],
      fuenteBilateralSeleccionada: {},
      fuentesBilaterales: [],
      fuenteMultilateralSeleccionada: {},
      fuentesMultilateral: [],
      sectoresBeneficiados: [],
      instituciones: [],
      datosBusqueda: {
        tipoConsulta: 0,
        tiposFuenteCooperanteId: 0
      },
      tiposConsulta: [
        //{ id: 1, tipo: "Reporte Matriz" },
        { id: 1, tipo: "Por Sector" },
        { id: 2, tipo: "Por Territorio" },
        { id: 3, tipo: "Por Institución" },
        { id: 4, tipo: "Por Fuente Cooperante" },
        { id: 5, tipo: "Por Estado del Proyecto" }
      ],
      frameUrl: ``,
      ...validations
    };
  },

  methods: {
    //Sectores beneficiados
    async obtenerSectoresBeneficiados() {
      this.sectoresBeneficiados = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "Sectores/all/1" })
        .then(res => {
          if (res.status === 200) {
            this.sectoresBeneficiados = res.data;
            this.sectoresBeneficiados.unshift({ id: 0, sector: "Todos" });
            this.datosBusqueda.sectoresId = 0;
          }
        })
        .catch(() => {
          this.sectoresBeneficiados = [];
        });
    },

    //Obtener departamentos
    async obtenerDepartamentos() {
      this.departamentosLoading = true;
      this.departamentos = [];
      //this.departamentosActividad = [];
      await this.$store
        .dispatch(OBTENER_DEPARTAMENTOS_PAIS, 94) //94 representa al país 94 (Guatemala)
        .then(res => {
          if (res.status === 200) {
            this.departamentos = res.data;
            this.departamentos.unshift({ id: 0, departamento: "Todos" });
            this.datosBusqueda.departamentosId = 0;
            //this.departamentosActividad = res.data;
          }
          this.departamentosLoading = false;
          // this.departamentosActividadLoading=false;
        })
        .catch(() => {
          this.departamentos = [];
          //this.departamentosActividad = [];
          this.departamentosLoading = false;
          //this.departamentosActividadLoading=false;
        });
    },

    async obtenerTiposFuenteCooperante() {
      this.tiposFuenteCooperante = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "TiposFuenteCooperante/all/1"
        })
        .then(res => {
          if (res.status === 200) {
            this.tiposFuenteCooperante = res.data;
            this.tiposFuenteCooperante.splice(1, 1);
            this.tiposFuenteCooperante.unshift({
              id: 0,
              nombreTipoFuente: "Todas"
            });
            this.datosBusqueda.tiposFuenteCooperanteId = 0;
          }
        })
        .catch(() => {
          this.tiposFuenteCooperante = [];
        });
    },

    async obtenerFuentesMultilateral() {
      this.fuentesMultilateral = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "FuenteMultiLateral/all/1"
        })
        .then(res => {
          if (res.status === 200) {
            this.fuentesMultilateral = res.data;
            this.fuentesMultilateral.unshift({
              id: 0,
              nombreFuenteMultilateral: "Todos"
            });
            this.datosBusqueda.fuentesMultilateralesId = 0;
          }
        })
        .catch(() => {
          this.fuentesMultilateral = [];
        });
    },

    async obtenerFuentesBilaterales() {
      this.fuentesBilaterales = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "FuentesBilaterales/all/1/0"
        })
        .then(res => {
          if (res.status === 200) {
            this.fuentesBilaterales = res.data;
            this.fuentesBilaterales.unshift({ id: 0, nombre: "Todos" });
            this.datosBusqueda.fuentesBilateralesId = 0;
          }
        })
        .catch(() => {
          this.fuentesBilaterales = [];
        });
    },

    obtenerEstadosProyecto() {
      this.estadosProyecto = [
        { id: 0, estado: "Todos" },
        { id: 1, estado: "Registro" },
        { id: 2, estado: "Seguimiento" },
        { id: 3, estado: "Solicitud de Cierre/Liquidación" },
        { id: 4, estado: "Cierre/Liquidación" },
        { id: 5, estado: "Solicitud de Finalización" },
        { id: 6, estado: "Finalizado" },
        { id: 7, estado: "Solicitud de Suspendido" },
        { id: 8, estado: "Suspendido" }
      ];
    },

    //Obtener entidades o instituciones
    async obtenerEntidades() {
      this.instituciones = [];
      this.ddInstitucionesLoading = true;
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: `Entidades/all/1` })
        .then(res => {
          if (res.status === 200) {
            this.instituciones = res.data;

            this.instituciones.splice(0, 0, {
              nombreInstitucion: "TODAS",
              id: 0,
              sigla: "Todas las instituciones",
              estadosId: 1,
              restrictiva: "N",
              estado: "Activo"
            });

            //this.departamentos.unshift({ id: 0, departamento: "Todos" });
            this.datosBusqueda.entidadId = 0;
          }
          this.ddInstitucionesLoading = false;
        })
        .catch(() => {
          this.instituciones = [];
          this.ddInstitucionesLoading = false;
        });
    },

    buscarProyectos() {
      this.frameUrl = `https://rpts.sicoopera.gt/jasperserver/rest_v2/reports/Reportes/ReporteGeneralCnsSector/ReporteGeneralCnsSector.pdf?endpoint=ReporteGeneralDocumentosProyecto/General/Consulta/1/1/0/0/0/0/0&j_password=reportes&j_username=reporte`;
    },
    prepararParametros() {
      if (this.datosBusqueda.tipoConsulta == 1) {
        this.datosConsulta.sectoresId = 0;
      } else if (this.datosBusqueda.tipoConsulta == 2) {
        this.datosBusqueda.departamentosId = 0;
      } else if (this.datosBusqueda.tipoConsulta == 3) {
        this.datosBusqueda.entidadId = 0;
      } else if (this.datosBusqueda.tipoConsulta == 4) {
        this.datosBusqueda.fuentesBilateralesId = 0;
        this.datosBusqueda.fuentesMultilateralesId = 0;
      } else if (this.datosBusqueda.tipoConsulta == 5) {
        this.datosBusqueda.estadoProyectoId = 0;
      }
    },
    //Tipo 1 pdf, 2 xls, 3 csv
    generarArchivo(tipoConsulta, tipoArchivo) {
      console.log(tipoConsulta + " " + tipoArchivo);

      //Si es por sector
      if (tipoConsulta === 1)
        this.frameUrl = `https://rpts.sicoopera.gt/jasperserver/rest_v2/reports/Reportes/ReporteGeneralCnsSector/ReporteGeneralCnsSector.${tipoArchivo}?endpoint=ReporteGeneralDocumentosProyecto/General/Consulta/${tipoConsulta}/${this.datosBusqueda.sectoresId}/0/0/0/0/0/0/0/0&j_password=reportes&j_username=reporte`;
      else if (tipoConsulta == 2)
        this.frameUrl = `https://rpts.sicoopera.gt/jasperserver/rest_v2/reports/Reportes/ReporteGeneralCnsDepartamento/ReporteGeneralCnsDepartamento.${tipoArchivo}?endpoint=ReporteGeneralDocumentosProyecto/General/Consulta/2/0/${this.datosBusqueda.departamentosId}/0/0/0/0/0/0/0&j_password=reportes&j_username=reporte`;
      else if (tipoConsulta == 3)
        this.frameUrl = `https://rpts.sicoopera.gt/jasperserver/rest_v2/reports/Reportes/ReporteGeneralCnsInstitucion/ReporteGeneralCnsInstitucion.${tipoArchivo}?endpoint=ReporteGeneralDocumentosProyecto/General/Consulta/3/0/0/${this.datosBusqueda.entidadId}/0/0/0/0/0/0&j_password=reportes&j_username=reporte`;
      else if (tipoConsulta == 4) {
        let idFC = 0;
        if (this.datosBusqueda.tiposFuenteCooperanteId == 1)
          idFC = this.datosBusqueda.fuentesBilateralesId;
        else if (this.datosBusqueda.tiposFuenteCooperanteId == 2)
          idFC = this.datosBusqueda.fuentesMultilateralesId;

        this.frameUrl = `https://rpts.sicoopera.gt/jasperserver/rest_v2/reports/Reportes/ReporteGeneralCnsFuenteCooperante/ReporteGeneralCnsFuenteCooperante.${tipoArchivo}?endpoint=ReporteGeneralDocumentosProyecto/General/Consulta/4/0/0/0/${this.datosBusqueda.tiposFuenteCooperanteId}/${idFC}/0/0/0/0&j_password=reportes&j_username=reporte`;
      } else if (tipoConsulta == 5)
        this.frameUrl = `https://rpts.sicoopera.gt/jasperserver/rest_v2/reports/Reportes/ReporteGeneralCnsEstado/ReporteGeneralCnsEstado.${tipoArchivo}?endpoint=ReporteGeneralDocumentosProyecto/General/Consulta/5/0/0/0/0/0/${this.datosBusqueda.estadoProyectoId}/0/0/0&j_password=reportes&j_username=reporte`;

      /*url por territorio/departamento
      https://rpts.sicoopera.gt/jasperserver/rest_v2/reports/Reportes/ReporteGeneralCnsDepartamento/ReporteGeneralCnsDepartamento.html?endpoint=ReporteGeneralDocumentosProyecto/General/Consulta/3/0/0/0/0/0/0&j_password=reportes&j_username=reporte
      */

      /*url por institucion
      https://rpts.sicoopera.gt/jasperserver/rest_v2/reports/Reportes/ReporteGeneralCnsInstitucion/ReporteGeneralCnsInstitucion.html?endpoint=ReporteGeneralDocumentosProyecto/General/Consulta/3/1/0/0/0/0/0&j_password=reportes&j_username=reporte
      */

      /*url por fuente cooperante
      https://rpts.sicoopera.gt/jasperserver/rest_v2/reports/Reportes/ReporteGeneralCnsFuenteCooperante/ReporteGeneralCnsFuenteCooperante.html?endpoint=ReporteGeneralDocumentosProyecto/General/Consulta/4/0/0/0/0/0/0&j_password=reportes&j_username=reporte
      */

      /*url por estado
      https://rpts.sicoopera.gt/jasperserver/rest_v2/reports/Reportes/ReporteGeneralCnsEstado/ReporteGeneralCnsEstado.html?endpoint=ReporteGeneralDocumentosProyecto/General/Consulta/5/0/0/0/0/0/0&j_password=reportes&j_username=reporte
      */

      window.open(this.frameUrl, "_blank");
    }
  },

  created() {
    this.obtenerEntidades();
    this.obtenerDepartamentos();
    this.obtenerTiposFuenteCooperante();
    this.obtenerFuentesMultilateral();
    this.obtenerFuentesBilaterales();
    this.obtenerEstadosProyecto();
    this.obtenerSectoresBeneficiados();
    this.datosBusqueda.estadoProyectoId = 0;
  }
};
</script>
<style lang=""></style>
